import React from 'react'
import PropTypes from 'prop-types'
import Filter from 'components/shared/filter/filter'

const ShippingLabelsFilters = props => {
  const { onFilterChange, removeFilter, shippers } = props

  const updateShipperFilter = e => {
    const filterValue = e.target.value

    if (filterValue === 'all' && removeFilter) {
      removeFilter('shipper')
    } else {
      if (onFilterChange) onFilterChange('shipper', filterValue)
    }
  }

  const handleSearchInput = e => {
    const filterValue = e.target.value

    if (filterValue && onFilterChange) {
      onFilterChange('shipmentNumber', filterValue)
    } else {
      if (removeFilter) removeFilter('shipmentNumber')
    }
  }

  return (
    <div className='shipping-labels-filters'>
      <Filter
        name='shipper'
        displayName='Shipper'
        defaultText='All Shippers'
        selectedValue={props.activeFilters.shipper || 'all'}
        onChange={updateShipperFilter}
        data={shippers}
      />
      <input
        className='filter__shipment_number'
        name='shipment_number'
        placeholder='Shipment number'
        type='search'
        onChange={handleSearchInput}
        value={props.activeFilters.shipmentNumber}
      />
      {props.children}
    </div>
  )
}

ShippingLabelsFilters.propTypes = {
  activeFilters: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func,
  removeFilter: PropTypes.func,
  children: PropTypes.array,
  shippers: PropTypes.array,
}

export default ShippingLabelsFilters
