import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { clearData, setGlobalFilter } from 'actions'
import ZipLogDatePicker from '../ziplogDatepicker/ziplogDatepicker'

export const DeliveryDatePicker = props => {
  const {
    deliveryDateRange,
    deliveryDate,
    deliveryDateFromState,
    deliveryDateRangeFromState,
    selectsDateRange,
    dispatch,
    context,
    onChange,
    minDate,
    maxDate,
    valuesFromState = true,
  } = props

  const selectedDeliveryDate = valuesFromState ? deliveryDateFromState : deliveryDate
  const selectedDeliveryDateRange = valuesFromState ? deliveryDateRangeFromState : deliveryDateRange

  const updateSelectedDate = date => {
    props.dispatch(clearData(context))
    props.dispatch(setGlobalFilter('deliveryDate', date))

    if (onChange) onChange(date)
  }

  const onDateRangeSelect = dates => {
    dispatch(setGlobalFilter('deliveryDateRange', dates))

    if (onChange) onChange(dates)
  }

  return (
    <ZipLogDatePicker
      dispatch={dispatch}
      defaultDate={selectedDeliveryDate}
      onDateSelect={updateSelectedDate}
      labelText='Delivery date'
      selectsDateRange={selectsDateRange}
      onDateRangeSelect={onDateRangeSelect}
      minDate={minDate}
      maxDate={maxDate}
      deliveryDateRange={selectedDeliveryDateRange}
    />
  )
}

DeliveryDatePicker.propTypes = {
  dispatch: PropTypes.func.isRequired,
  deliveryDateFromState: PropTypes.string,
  deliveryDate: PropTypes.string,
  context: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  selectsDateRange: PropTypes.bool,
  deliveryDateRangeFromState: PropTypes.array,
  deliveryDateRange: PropTypes.array,
  onChange: PropTypes.func,
  valuesFromState: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    deliveryDateFromState: state.globalFilters.deliveryDate,
    deliveryDateRangeFromState: state.globalFilters.deliveryDateRange,
  }
}

const connectedDeliveryDatePicker = connect(mapStateToProps)(DeliveryDatePicker)

export default connectedDeliveryDatePicker
