// Shared methods used to query logistics graphql endpoint
import superagent from 'superagent'
import { getFeatureFlags } from './query.helper'

export const post = (query, flags) => {
  const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/graphql`
  const logisticsToken = localStorage.getItem('logisticsToken')
  const featureFlags = flags || getFeatureFlags()
  const body = { query: query, feature_flags: featureFlags }

  return superagent
    .post(url)
    .send(body)
    .set('Authorization', `Bearer ${logisticsToken}`)
    .set('Content-Type', 'application/json')
    .set('X-Issuer', 'ziplog')
}
