import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AppConfig from 'config'
import Filter from 'components/shared/filter/filter'
import { setFilter } from 'actions/shippingLabels'

export const CountryFilter = ({
  dispatch,
  country,
  countries,
  onChange,
  continent,
  continentFromState,
  countryFromState,
  valuesFromState = true,
}) => {
  const selectedContinent = valuesFromState ? continentFromState : continent
  const selectedCountry = valuesFromState ? countryFromState : country

  const handleChange = e => {
    dispatch(setFilter('country', e.target.value))

    if (onChange) onChange(e.target.value)
  }

  const calculateAvailableCountries = () => {
    let allCountries = AppConfig.countries.filter(c => countries.includes(c.name))

    return allCountries.filter(c => c.continent === selectedContinent).map(c => c.name)
  }

  const continentNotSelected = () => !selectedContinent

  return (
    <Filter
      name='country'
      displayName='country'
      displayUpcase
      onChange={handleChange}
      data={calculateAvailableCountries()}
      selectedValue={selectedCountry}
      disabled={continentNotSelected()}
      defaultValue='All'
      defaultText='All Countries'
      labelText='Country'
    />
  )
}

CountryFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  country: PropTypes.string,
  continentFromState: PropTypes.string,
  countryFromState: PropTypes.string,
  continent: PropTypes.string,
  onChange: PropTypes.func,
  valuesFromState: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    continentFromState: state.shippingLabels.continent,
    countryFromState: state.shippingLabels.country,
  }
}

const connectedCountryFilter = connect(mapStateToProps)(CountryFilter)

export default connectedCountryFilter
