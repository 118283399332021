import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AppConfig from 'config'
import Filter from 'components/shared/filter/filter'
import { setFilter } from 'actions/shippingLabels'

export const ContinentFilter = ({
  dispatch,
  continent,
  continentFromState,
  onChange,
  valuesFromState = true,
}) => {
  const selectedContinent = valuesFromState ? continentFromState : continent

  const handleChange = e => {
    dispatch(setFilter('continent', e.target.value))
    dispatch(setFilter('country', 'All'))

    if (onChange) onChange(e.target.value)
  }

  return (
    <Filter
      name='continent'
      displayName='continent'
      displayUpcase
      onChange={handleChange}
      data={AppConfig.continents}
      selectedValue={selectedContinent}
      defaultValue=''
      defaultText='Please select'
      labelText='Continent'
    />
  )
}

ContinentFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  continent: PropTypes.string,
  continentFromState: PropTypes.string,
  valuesFromState: PropTypes.bool,
  onChange: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    continentFromState: state.shippingLabels.continent,
  }
}

const connectedContinentFilter = connect(mapStateToProps)(ContinentFilter)

export default connectedContinentFilter
