import { call, put } from 'redux-saga/effects'

import { getShippingLabels } from 'apis/shippingLabels/graphql.api'
import { graphqlApi } from 'apis/graphql.api'
import authenticationApi from 'apis/authentication.api'

import {
  SHIPPING_LABELS_FETCH_SUCCEEDED,
  SHIPPING_LABELS_FETCH_FAILED,
  SHIPPING_LABELS_MISSING_LABELS_FETCH_SUCCEEDED,
  SHIPPING_LABELS_MISSING_LABELS_FETCH_FAILED,
} from 'actions/types'

export function* fetchShippingLabels(action) {
  const { missingLabels, pendingLabels } = action.data
  const isMissing = missingLabels || pendingLabels

  try {
    const response = yield call(getShippingLabels, action.data, action.featureFlags)

    graphqlApi.handleGraphqlErrors(response)
    yield put({
      type: isMissing
        ? SHIPPING_LABELS_MISSING_LABELS_FETCH_SUCCEEDED
        : SHIPPING_LABELS_FETCH_SUCCEEDED,
      shippingLabels: response.body.data.shippingLabels,
      shippers: response.body.data.shippers,
    })
  } catch (error) {
    const failureAction = {
      type: isMissing ? SHIPPING_LABELS_MISSING_LABELS_FETCH_FAILED : SHIPPING_LABELS_FETCH_FAILED,
    }

    authenticationApi.handleError(error, action, failureAction)
    yield put(failureAction)
  }
}
