import React from 'react'
import PropTypes from 'prop-types'
import Filter from 'components/shared/filter/filter'

function ShippersFilters(props) {
  const [availableFilters, setAvailableFilters] = React.useState({
    countries: [],
  })

  React.useEffect(() => {
    let countries = new Set()

    if (props.data) {
      props.data?.forEach(shipper => countries.add(shipper.country))
    }

    setAvailableFilters({
      countries: Array.from(countries),
    })
  }, [props.data])

  const updateShipperFilter = e => {
    const filterName = e.target.name
    const filterValue = e.target.value

    let newFilters = props.activeFilters

    if (filterValue === 'all') {
      delete newFilters[filterName]
    } else if (filterValue === 'null') {
      newFilters[filterName] = null
    } else {
      newFilters[filterName] = filterValue
    }

    return props.onFiltersUpdate(props.data, newFilters)
  }

  if (props.data && props.data.length > 0) {
    return (
      <div>
        <Filter
          name='country'
          displayName='Country'
          defaultText='All Countries'
          onChange={updateShipperFilter}
          data={availableFilters.countries}
        />
      </div>
    )
  }

  return null
}

ShippersFilters.propTypes = {
  onFiltersUpdate: PropTypes.func.isRequired,
  activeFilters: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  children: PropTypes.array,
}

export default ShippersFilters
