import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase, startCase, isNil } from 'lodash'

import './baseTable.css'

const PaginatedBaseTable = ({
  headers,
  data,
  columnRenames,
  rowsPerPage,
  tableName,
  currentPage,
  setCurrentPage,
  totalCount,
  onHandleNext,
  onHandlePrevious,
}) => {
  const getColumnDisplayName = columnName => {
    return columnRenames && Object.prototype.hasOwnProperty.call(columnRenames, columnName)
      ? columnRenames[columnName]
      : startCase(columnName)
  }

  const renderHead = () => {
    const columnNames = headers.map(columnName => getColumnDisplayName(columnName))

    return columnNames.map(header => (
      <th key={header} className={`${tableName}__header-column ${tableName}__${kebabCase(header)}`}>
        {header}
      </th>
    ))
  }

  const renderRows = () => {
    return data?.map((tableRecord, index) => (
      <tr className={`${tableName}__table-row`} key={index}>
        {renderColumns(tableRecord)}
      </tr>
    ))
  }

  const renderColumns = rowData => {
    return headers.map(columnName => {
      const value = rowData[columnName]

      return (
        <td
          key={`${columnName}-${value}`}
          className={`${tableName}__row-column ${tableName}__${kebabCase(columnName)}`}
        >
          {isNil(value) ? 'N/A' : value}
        </td>
      )
    })
  }

  const totalPages = Math.ceil(totalCount / rowsPerPage)

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
      onHandlePrevious()
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
      onHandleNext()
    }
  }

  return (
    <div className='table-container'>
      <table className={`${tableName} base-table`}>
        <thead className={`${tableName}__table-head`}>
          <tr>{renderHead()}</tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>

      <div className='pagination-controls'>
        <button
          name='previous'
          onClick={handlePrevious}
          className={currentPage === 1 ? 'hidden' : ''}
        >
          Previous
        </button>
        <span className='page-info'>
          Page {currentPage} of {totalPages}
        </span>
        <button
          name='next'
          onClick={handleNext}
          className={currentPage === totalPages ? 'hidden' : ''}
        >
          Next
        </button>
      </div>
    </div>
  )
}

PaginatedBaseTable.defaultProps = {
  tableName: 'paginated-base-table',
  data: [],
  columnRenames: {},
  rowsPerPage: 20, // default 20 lines per page
  onHandleNext: f => f,
  onHandlePrevious: f => f,
}

PaginatedBaseTable.propTypes = {
  tableName: PropTypes.string,
  headers: PropTypes.array.isRequired,
  data: PropTypes.array,
  columnRenames: PropTypes.object,
  rowsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalCount: PropTypes.number,
  onHandleNext: PropTypes.func,
  onHandlePrevious: PropTypes.func,
}

export default PaginatedBaseTable
