import React, { Fragment } from 'react'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import BaseTable from 'components/shared/baseTable/baseTable'
import ShippersJSON from './shippers.json'
import { filter } from 'lodash'
import './shippers.css'

import ShippersFilters from './shippersFilters/shippersFilters'

const INFO_MESSAGE_TEXT = 'This page contains all the shippers and their respective contact points.'

const TABLE_NAME = 'shippers-table'
const TABLE_HEADERS = ['Name', 'Country', 'Contact Name', 'Contact Phone', 'Contact Email']

export const Shippers = _ => {
  const [activeFilters, setActiveFilters] = React.useState({})
  const [filteredData, setFilteredData] = React.useState(ShippersJSON)

  const filterData = newFilters => {
    setActiveFilters(newFilters)
    setFilteredData(ShippersJSON)
  }

  const filterShipper = (data, activeFilters) => {
    setFilteredData(filter(data, activeFilters))
  }

  React.useEffect(() => {
    filterData({})
  }, [ShippersJSON])

  const renderRows = () => {
    return filteredData.map((shipper, index) => (
      <Fragment key={index}>
        <tr className={`${TABLE_NAME}__table-row shipper-data`} key={`${index}-1`}>
          {renderColumns(shipper)}
        </tr>
      </Fragment>
    ))
  }

  const multiDataRow = jsonRow => {
    let rows = Array.isArray(jsonRow) ? jsonRow.join('</br>') : jsonRow
    return <span dangerouslySetInnerHTML={{ __html: rows }} />
  }

  const renderRowCell = data => {
    return <td className={`${TABLE_NAME}__row-column`}> {data} </td>
  }

  const renderColumns = shipper => {
    const shipperRow = (
      <Fragment>
        {renderRowCell(shipper.name)}
        {renderRowCell(shipper.country.toUpperCase())}
        {renderRowCell(multiDataRow(shipper.contactName))}
        {renderRowCell(multiDataRow(shipper.phone))}
        {renderRowCell(multiDataRow(shipper.email))}
      </Fragment>
    )

    return shipperRow
  }

  return (
    <Fragment>
      <InfoMessage message={INFO_MESSAGE_TEXT} />
      <ShippersFilters
        onFiltersUpdate={filterShipper}
        data={ShippersJSON}
        activeFilters={activeFilters}
      />
      <BaseTable headers={TABLE_HEADERS} tableName={TABLE_NAME}>
        {renderRows()}
      </BaseTable>
    </Fragment>
  )
}

export default Shippers
