import React from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { capitalize } from 'lodash'

import AppLogo from './appLogo/appLogo'
import AuditLogs from './auditLogs/auditLogs'
import DeliveryOptions from './deliveryOptions/deliveryOptions'
import DeliveryOptionsNew from './deliveryOptionsNew/deliveryOptionsNew'
import DeliveryOptionsUpdate from './deliveryOptionsUpdate/deliveryOptionsUpdate'
import DynamicOptionsUpdate from './dynamicOptionsUpdate/dynamicOptionsUpdate'
import DeliveryOptionsPause from './deliveryOptionsPause/deliveryOptionsPause'
import DeliveryDateExceptions from './deliveryDateExceptions/deliveryDateExceptions'
import OrderMovement from './orderMovement/orderMovement'
import FailedOrderMovements from './failedOrderMovements/failedOrderMovements'
import DeliveryPromises from './deliveryPromises/deliveryPromises'
import DeliveryPromisesNew from './deliveryPromisesNew/deliveryPromisesNew'
import DeliveryPromisesReplace from './deliveryPromisesReplace/deliveryPromisesReplace'
import DeliveryTracking from './deliveryTracking/deliveryTracking'
import FeatureFlags from './featureFlags/featureFlags'
import Header from './header/header'
import LostLabels from './lostLabels/lostLabels'
import PendingLabels from './pendingLabels/pendingLabels'
import ModalContainer from './modal/modalContainer'
import Navigation from './navigation/navigation'
import NotFound from './notFound/notFound'
import ShipmentOptions from './shipmentOptions/shipmentOptions'
import ShipmentOptionsActions from './shipmentOptionsActions/shipmentOptionsActions'
import ShippingCsvs from './shippingCsvs/shippingCsvs'
import ShippingLabels from './shippingLabels/shippingLabels'
import ShippingLabel from './shippingLabel/shippingLabel'
import shipmentNumberRegex from 'helpers/shipmentNumberRegex'
import TrackingActivitiesCsvs from './trackingActivitiesCsvs/trackingActivitiesCsvs'
import TrackingCsvs from './trackingCsvs/trackingCsvs'
import Users from './users/users'
import Shippers from './shippers/shippers'
import ZipcodesNew from './zipcodesNew/zipcodesNew'
import ZipcodesUpdate from './zipcodesUpdate/zipcodesUpdate'
import Alert from './shared/Alert/Alert'
import HealthCheck from './HealthCheck/HealthCheck'
import { privateRoute } from './privateRoute/privateRoute'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './app.css'

const App = () => {
  document.title = `ZipLog ${capitalize(process.env.REACT_APP_ENV.replace('production', ''))}`

  const renderHealthRouter = () => {
    return <HealthCheck />
  }

  if (location.pathname === '/liveness') return renderHealthRouter()

  return (
    <Router>
      <div className='app'>
        <FeatureFlags />
        <ModalContainer />

        <AppLogo />
        <Navigation />
        <Header />

        <div className='app__main-body'>
          <div className='app__routes'>
            <Switch>
              <Route exact path='/' render={() => <Redirect to='/delivery_coverage' />} />
              <Route
                exact
                path='/delivery_coverage'
                component={privateRoute(DeliveryOptions).connectedWrapperComponent}
              />
              <Route
                exact
                path='/zipcodes/new'
                component={privateRoute(ZipcodesNew).connectedWrapperComponent}
              />
              <Route
                exact
                path='/zipcodes/update'
                component={privateRoute(ZipcodesUpdate).connectedWrapperComponent}
              />
              <Route
                exact
                path='/delivery_options/new'
                component={privateRoute(DeliveryOptionsNew).connectedWrapperComponent}
              />
              <Route
                exact
                path='/delivery_options/update'
                component={privateRoute(DeliveryOptionsUpdate).connectedWrapperComponent}
              />
              <Route
                exact
                path='/delivery_options/pause'
                component={privateRoute(DeliveryOptionsPause).connectedWrapperComponent}
              />
              <Route
                exact
                path='/delivery_options/dynamic_options'
                component={privateRoute(DynamicOptionsUpdate).connectedWrapperComponent}
              />
              <Route
                exact
                path='/order_movement'
                component={privateRoute(OrderMovement).connectedWrapperComponent}
              />
              <Route
                exact
                path='/failed_order_movements'
                component={privateRoute(FailedOrderMovements).connectedWrapperComponent}
              />
              <Route
                exact
                path='/exceptions'
                component={privateRoute(DeliveryDateExceptions).connectedWrapperComponent}
              />
              <Route
                exact
                path='/shipping_labels'
                component={privateRoute(ShippingLabels).connectedWrapperComponent}
              />
              <Route
                exact
                path={`/shipping_labels/:shipmentNumber${shipmentNumberRegex()}`}
                component={ShippingLabel}
              />
              <Route
                exact
                path='/missing_labels'
                component={privateRoute(LostLabels).connectedWrapperComponent}
              />
              <Route
                exact
                path='/pending_labels'
                component={privateRoute(PendingLabels).connectedWrapperComponent}
              />
              <Route
                exact
                path='/shipping_csvs'
                component={privateRoute(ShippingCsvs).connectedWrapperComponent}
              />
              <Route
                exact
                path='/audit_logs'
                component={privateRoute(AuditLogs).connectedWrapperComponent}
              />
              <Route
                exact
                path='/delivery_tracking'
                component={privateRoute(DeliveryTracking).connectedWrapperComponent}
              />
              <Route
                exact
                path='/tracking_activities_csvs'
                component={privateRoute(TrackingActivitiesCsvs).connectedWrapperComponent}
              />
              <Route
                exact
                path='/tracking_csvs'
                component={privateRoute(TrackingCsvs).connectedWrapperComponent}
              />
              <Route
                exact
                path='/shipment_options'
                component={privateRoute(ShipmentOptions).connectedWrapperComponent}
              />
              <Route
                exact
                path='/shipment_options/new'
                component={privateRoute(ShipmentOptionsActions).connectedWrapperComponent}
              />
              <Route
                exact
                path='/shipment_options/remove'
                component={privateRoute(ShipmentOptionsActions).connectedWrapperComponent}
              />
              <Route
                exact
                path='/delivery_promises'
                component={privateRoute(DeliveryPromises).connectedWrapperComponent}
              />
              <Route
                exact
                path='/delivery_promises/new'
                component={privateRoute(DeliveryPromisesNew).connectedWrapperComponent}
              />
              <Route
                exact
                path='/delivery_promises/replace'
                component={privateRoute(DeliveryPromisesReplace).connectedWrapperComponent}
              />
              <Route
                exact
                path='/users'
                component={privateRoute(Users).connectedWrapperComponent}
              />
              <Route
                exact
                path='/shippers'
                component={privateRoute(Shippers).connectedWrapperComponent}
              />
              <Route component={privateRoute(NotFound).connectedWrapperComponent} />
            </Switch>
          </div>
        </div>
      </div>
      <Alert />
    </Router>
  )
}

export default App
