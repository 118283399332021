import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BrandsFilter from 'components/shared/brandsFilter/brandsFilter'
import CountriesFilter from 'components/shared/countriesFilter/countriesFilter'
import DeliveryDatePicker from 'components/shared/deliveryDatePicker/deliveryDatePicker'
import InfoMessage from 'components/shared/infoMessage/infoMessage'
import LostLabelsPostButton from './lostLabelsPostButton/lostLabelsPostButton'
import LostLabelsTable from './lostLabelsTable/lostLabelsTable'

import { SHIPPING_LABELS } from 'actions/context'
import { setGlobalFilter } from 'actions/index'

import './lostLabels.css'
import ShippingLabelsFilters from 'components/shippingLabels/shippingLabelsFilters/shippingLabelsFilters'
import { filterShippingLabel } from '../../helpers/helpers'

const INFO_MESSAGE_TEXT =
  'This page contains the shipments with label generation errors for all shippers.'

export const LostLabels = props => {
  const { data, dispatch } = props
  const [activeFilters, setActiveFilters] = React.useState({})
  const [filteredData, setFilteredData] = React.useState(data)
  const [shippers, setShippers] = React.useState([])

  const graphqlApiData = () => {
    return {
      missingLabels: true,
      countries: props.countries,
      brand: props.brands,
      deliveryDateRange: props.deliveryDateRange,
    }
  }

  const updateData = params => {
    const data = params ?? {
      ...graphqlApiData(),
      countries: props.countriesAllowedToView,
    }

    props.dispatch({
      type: `[${SHIPPING_LABELS}]_MISSING_LABELS_FETCH_REQUESTED`,
      data,
    })
  }

  const filterData = (filterName, filterValue) => {
    setActiveFilters(prev => ({ ...prev, [filterName]: filterValue }))
  }

  const removeFilter = filterName => {
    setActiveFilters(({ [filterName]: _, ...rest }) => rest)
  }

  React.useEffect(() => {
    dispatch(setGlobalFilter('brands', 'all_with_extra_brands'))
    updateData()
    return () => {
      dispatch(setGlobalFilter('brands', 'all'))
    }
  }, [])

  React.useEffect(() => {
    setFilteredData(filterShippingLabel(data, activeFilters))
  }, [activeFilters])

  React.useEffect(() => {
    filterData({})
    filterShippers()
  }, [data])

  const filterShippers = () => {
    let shippers = new Set()

    if (data) {
      props.data.forEach(label => shippers.add(label.shipper))
    }

    setShippers(Array.from(shippers))
  }

  return (
    <Fragment>
      <InfoMessage message={INFO_MESSAGE_TEXT} />
      <div className='filters-block'>
        <CountriesFilter allCountries={props.countriesAllowedToView} context={SHIPPING_LABELS} />
        <BrandsFilter context={SHIPPING_LABELS} includeExtraBrands />
        <DeliveryDatePicker context={SHIPPING_LABELS} selectsDateRange />
        <LostLabelsPostButton graphqlApiData={graphqlApiData()} />
      </div>
      <ShippingLabelsFilters
        shippers={shippers}
        activeFilters={activeFilters}
        onFilterChange={filterData}
        removeFilter={removeFilter}
      />
      <LostLabelsTable
        data={filteredData}
        updateData={updateData}
        graphqlApiData={graphqlApiData()}
        status={props.status}
        dispatch={props.dispatch}
      />
    </Fragment>
  )
}

LostLabels.propTypes = {
  dispatch: PropTypes.func.isRequired,
  brands: PropTypes.string,
  countriesAllowedToView: PropTypes.array,
  countries: PropTypes.array,
  data: PropTypes.array,
  deliveryDateRange: PropTypes.array.isRequired,
  status: PropTypes.number.isRequired,
}

const mapStateToProps = state => {
  return {
    brands: state.globalFilters.brands,
    countriesAllowedToView: state.currentUser.countriesAllowedToView,
    countries: state.globalFilters.countries,
    data: state.shippingLabels.missingLabelsData,
    deliveryDateRange: state.globalFilters.deliveryDateRange,
    status: state.shippingLabels.status,
  }
}

const connectedLostLabels = connect(mapStateToProps)(LostLabels)

export default connectedLostLabels
