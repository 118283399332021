import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import formatDate from 'helpers/formatDate'
import PaginatedBaseTable from 'components/shared/baseTable/paginatedBaseTable'
import ViewLabelLink from './viewLabelLink/viewLabelLink'
import OrderPageLink from 'components/shared/orderPageLink/orderPageLink'

import { STATUSES } from 'helpers/constants'

import './shippingLabelsTable.css'

function ShippingLabelsTable(props) {
  const fields = [
    'shipmentNumber',
    'shipmentDate',
    'deliveryDate',
    'shipper',
    'trackingNumber',
    'viewLabel',
  ]

  const rowData = () => {
    return props.data.map(label => ({
      shipmentNumber: <OrderPageLink shipmentNumber={label.shipmentNumber} />,
      shipmentDate: formatDate(label.shipmentDate),
      deliveryDate: formatDate(label.deliveryDate),
      shipper: label.shipper,
      trackingNumber: label.trackingNumber,
      viewLabel: (
        <ViewLabelLink shipmentNumber={label.shipmentNumber} featureFlags={props.featureFlags} />
      ),
    }))
  }

  if (props.status === STATUSES.requested && isEmpty(props.data)) {
    return <div className='shipping-labels-table__loader'>Loading...</div>
  }

  if (isEmpty(props.data)) {
    return <div className='shipping-labels-table__no-results-msg'>No shipping labels found.</div>
  }

  return (
    <PaginatedBaseTable
      tableName='shipping-labels-table'
      headers={fields}
      data={rowData()}
      columnRenames={{
        shipmentNumber: 'Shipment #',
        trackingNumber: 'Tracking #',
        viewLabel: '',
      }}
      currentPage={props.currentPage}
      setCurrentPage={props.setCurrentPage}
      totalCount={props.totalCount}
      onHandleNext={props.handleNextPage}
      onHandlePrevious={props.handlePrevPage}
    />
  )
}

ShippingLabelsTable.propTypes = {
  status: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      shipmentNumber: PropTypes.string.isRequired,
      shipmentDate: PropTypes.string.isRequired,
      deliveryDate: PropTypes.string.isRequired,
      shipper: PropTypes.string.isRequired,
      trackingNumber: PropTypes.string,
    }),
  ).isRequired,
  featureFlags: PropTypes.array,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalCount: PropTypes.number,
  handleNextPage: PropTypes.func,
  handlePrevPage: PropTypes.func,
}

export default ShippingLabelsTable
