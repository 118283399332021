import React, { useEffect } from 'react'

const csvDownloadLink = props => {
  const { continent, country, brand, deliveryDateRange, fetchCsvData, csvData, setCsvData } = props

  const buildFilename = () => {
    const continentWithoutSpaces = continent.replace(/\s/g, '')
    const formattedDateRange = deliveryDateRange.join('-to-')
    const formattedCountry = country.toUpperCase() === 'ALL' ? 'All_Countries' : country
    const formattedBrand = brand.toUpperCase() === 'ALL' ? 'All_Brands' : brand

    return `tracking_${continentWithoutSpaces}_${formattedCountry}_${formattedBrand}_${formattedDateRange}.csv`
  }

  const generateAndDownloadCsv = () => {
    const headers = Object.keys(csvData[0])

    const dataRows = csvData.map(row => headers.map(header => row[header] ?? '').join(','))

    const rows = [headers.join(','), ...dataRows].join('\n')

    const blob = new Blob([rows], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url

    a.download = buildFilename()
    a.target = '_self'

    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
    URL.revokeObjectURL(url)
    setCsvData([])
  }

  useEffect(() => {
    if (csvData.length) generateAndDownloadCsv()
  }, [csvData])

  return (
    <button className='csv-download-button button button-secondary' onClick={fetchCsvData}>
      Export CSV
    </button>
  )
}

export default csvDownloadLink
